.presentation {
    text-align: center;
    margin: 0 0 0 0;
    background-color: #CEC1A8;
    position: relative;
    border-bottom: solid 1rem #353A48; 

  }
  
  .contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageContainer {
    flex: 1;
    display: flex; 
    max-width: 30%;
    justify-content: center; 
    padding: 10px; 
   
  }

  .imageContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border: 7px solid #353A48; 
    border-radius: 10px; 
    margin: 5em 0em 5em 5em
  }
  
  .textContainer h1 {
    font-size: 5em;
    font-style: italic;
  }

  .textContainer h2 {
    font-size: 2rem;
  }
  .textContainer h3 {
    font-size: 1.5rem;
    font-style: oblique;
    margin-bottom: 1em;
  }

  .textContainer p {
    font-size: 2rem; 
  }

  .textContainer ul {
    font-size: 2rem;
    font-weight: bold;
  }

  img {
    width: auto;
    height: auto;
  }

  .textContainer {
    flex: 1;
    
    align-items: center;
    padding-right: 5em;
    padding-left: 5em;
    justify-content: center;
    margin-bottom: 80px;
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .card {
    background-color:#DCD9D6;
    border: 5px solid #353A48;
    border-radius: 5px;
    padding: 10px;
    flex: 1;
    max-width: calc(33.33% - 10px);
    font-size: x-large;
    font-weight:bold;
    position: relative;
  }
  
  .socialIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }

  .socialIcon {
    margin-right: 10px;
  }
 
  .linkContainer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  
  .link {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
  }
  
  .link a {
    font-size: 2em;
    text-decoration: none;
    color: #333;
  }
  
  .link:hover {
    background-color: #808DA2;
  }