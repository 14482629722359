.footer {
    background-color: #f8f9fa;
    text-align: center;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
  }

  .socialIconContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .socialIcon {
    margin-right: 10px;
  }