.linkSection {
  font-size: 2em;
  background-color: #DCD9D6;
  margin-top: 0px;
  padding: 2em 0;
  text-align: center;
  border-bottom: solid 1rem #353A48; 
}

.linkSection h2 { 
  margin-top: 0px; 
  padding-top: 1em;
}

.linkSection p {
  font-size: 0.5em;
  margin-bottom: 0px;
  padding-bottom: 1em;
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;

}

.linkContainer {
  display: flex;
  justify-content: center;
}

.link {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
}

.link a {
  text-decoration: none;
  color: #333;
}

.link:hover {
  background-color: #CEC1A8;
}
