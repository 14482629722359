/* styles.module.css */

.faq-section {
  text-align: center;
  border-bottom: solid 1rem #353A48; 
  font-size: 4em;
  background-color: #C2CAE6;
}

.faq-section h2 { margin-top: 0px; padding-top: 1em;}

.faq-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;
}

.faq-card {
  width: 50%;
  margin-bottom: 10px;
  background-color: #C2CAE6;
}

.faq-question-container {
  display: flex;
  align-items: center;
}

.faq-toggle-sign {
  margin-left: auto;
  font-weight: bold;
}

.faq-question {
  cursor: pointer;
  font-family: "Teko", sans-serif;
  font-size: 3rem;
  padding: 10px 30px 10px 10px; /* Adjusted padding to make space for the '+' sign */
  width: 100%;
  background-color: #DCD9D6;
  border-bottom: 1px solid #ccc;
  text-align: left;
  position: relative;
}

.faq-question:hover {
  background-color: #e0e0e0;
  cursor: url('../../images/shit.png'), auto;

}

.faq-question::after {
  content: '+'; /* Added '+' content */
  font-weight: bold; /* Made the '+' sign bold */
  position: absolute; /* Positioned absolutely */
  right: 10px; /* Adjusted right position */
}

.faq-answer {
  font-size: 2rem;
  padding: 10px;
  background-color:#DCD9D6; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}

.faq-question.active + .faq-answer {
  display: block;
}

.faq-question.active::after {
  content: '-'; /* Changed '+' to '-' when question is active */
  font-weight: bold; /* Make the '-' sign bold */
}