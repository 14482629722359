.header {
  background-color: dimgrey;
  text-align: center;
  height: auto;
  border-bottom: solid 1rem #353A48; 
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 0rem;
}

.headerImage {
  width: 100%;
  height: 50%; 
  margin-right: 0px;
}

.headerTitle {
  margin: 0;
  color: aliceblue;
}
